document.addEventListener('DOMContentLoaded', function () {
	/********** Formulaire autologin ************/
	const ballID = localStorage.getItem('ballID');
	const ballExp = localStorage.getItem('ballExp');
	const ballSoHard = localStorage.getItem('ballSoHard');
	const expired = new Date(ballExp) < new Date();
	if (ballID !== null && ballExp !== null && ballSoHard !== null) {
		if (!expired) {
			const monform = document.createElement('form');
			monform.method = 'post';
			monform.action = 'utilisateur/autologin';
			const ballIDinput = document.createElement('input');
			ballIDinput.type = 'hidden';
			ballIDinput.name = 'ballID';
			ballIDinput.value = ballID;
			monform.appendChild(ballIDinput);
			const ballExpinput = document.createElement('input');
			ballExpinput.type = 'hidden';
			ballExpinput.name = 'ballExp';
			ballExpinput.value = ballExp;
			monform.appendChild(ballExpinput);
			const ballSHinput = document.createElement('input');
			ballSHinput.type = 'hidden';
			ballSHinput.name = 'ballSoHard';
			ballSHinput.value = ballSoHard;
			monform.appendChild(ballSHinput);
			document.querySelector('#connexion').after(monform);
			monform.submit();
		}
	}

	// Revealer mdp
	$('.revealer').on('mouseenter', function () {
		$(this).next().find('input').prop('type', 'text');
		$(this).addClass('glyphicon-eye-close').removeClass('glyphicon-eye-open');
	}).on('mouseleave', function () {
		$(this).next().find('input').prop('type', 'password');
		$(this).addClass('glyphicon-eye-open').removeClass('glyphicon-eye-close');
	});
});